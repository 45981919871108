import axios from 'axios';
import authHeader from './auth.header';

const USER_URL = `https://api.myfeed.larodev.com/api/users`;
// const USER_URL = `http://localhost:8081/api/users`;

class UserService {
    getIGAccounts() {
        return axios.get(`${USER_URL}/ig-accounts`, { headers: authHeader() }).then((response) => {
            return response.data;
        });
    }
}

export default new UserService();
