import axios from 'axios';
import authHeader from './auth.header';

const AUTH_URL = `https://api.myfeed.larodev.com/api/auth`;
// const AUTH_URL = `http://localhost:8081/api/auth`;

class AuthService {
    login(user) {
        return axios
            .post(`${AUTH_URL}/login`, {
                email: user.email,
                password: user.password,
            })
            .then((response) => {
                if (response.data.token) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                }

                return response.data;
            });
    }

    logout() {
        localStorage.removeItem('user');
    }

    register(user) {
        return axios.post(`${AUTH_URL}/signup`, {
            username: user.username,
            email: user.email,
            password: user.password,
        });
    }

    async loginToIG(code) {
        return await axios.post(
            `${AUTH_URL}/instagram/basic`,
            {
                code: code,
            },
            { headers: authHeader() },
        );
    }
}

export default new AuthService();
