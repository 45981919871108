import { RouteRecordRaw } from 'vue-router';

export const PublicRoutes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        redirect: '/feed/media',
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/modules/Auth/Login.vue'),
    },
    {
        path: '/feed/media',
        name: 'feed.media',
        component: () => import('@/modules/Feed/Media.vue'),
    },
    {
        path: '/feed/reels',
        name: 'feed.reels',
        component: () => import('@/modules/Feed/Media.vue'),
    },
    {
        path: '/calendar',
        name: 'calendar',
        component: () => import('@/modules/Calendar/Calendar.vue'),
    },

    {
        path: '/oauth',
        name: 'inst-auth',
        component: () => import('@/modules/Auth/IGAuth.vue'),
    },
];
