import UserService from '@/services/user.service';
import { createStore } from 'vuex';

const initialState = {
    ig_accounts: [],
};

export const user = {
    namespaced: true,
    state: initialState,
    actions: {
        getIGAccounts({ commit }) {
            return UserService.getIGAccounts().then(
                (response) => {
                    commit('getIGAccountsSuccess', response);
                    return Promise.resolve(response);
                },
                (error) => {
                    return Promise.reject(error);
                },
            );
        },
    },
    mutations: {
        getIGAccountsSuccess(state, accounts) {
            state.ig_accounts = accounts;
        },
    },
};
